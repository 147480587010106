import React, { useState, useRef } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import emailjs from "@emailjs/browser";
// import apiKeys from "./apikeys";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import "./App.css";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  // Form,
  // FormGroup,
  Row,
  Col,
  // Input,
  Container,
} from "reactstrap";
import { Link } from "react-scroll";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faFacebook,
//   faTwitter,
//   faInstagram,
//   faYoutube,
//   faLinkedin
// } from "@fortawesome/free-brands-svg-icons";

import { ReactComponent as Icon1 } from "./assets/svgs/icon1.svg";
import { ReactComponent as Icon2 } from "./assets/svgs/icon2.svg";
import { ReactComponent as Icon3 } from "./assets/svgs/icon3.svg";
import { ReactComponent as Icon4 } from "./assets/svgs/icon4.svg";

import { ReactComponent as FeatureIcon1 } from "./assets/svgs/featureIcon1.svg";
import { ReactComponent as FeatureIcon2 } from "./assets/svgs/featureIcon2.svg";
import { ReactComponent as FeatureIcon3 } from "./assets/svgs/featureIcon3.svg";
import { ReactComponent as FeatureIcon4 } from "./assets/svgs/featureIcon4.svg";
import { ReactComponent as FeatureIcon5 } from "./assets/svgs/featureIcon5.svg";
import { ReactComponent as FeatureIcon6 } from "./assets/svgs/featureIcon6.svg";
import { ReactComponent as FeatureIcon7 } from "./assets/svgs/featureIcon7.svg";
import { ReactComponent as FeatureIcon8 } from "./assets/svgs/featureIcon8.svg";
import { ReactComponent as FeatureIcon9 } from "./assets/svgs/featureIcon9.svg";
import { ReactComponent as FeatureIcon10 } from "./assets/svgs/featureIcon10.svg";

import { ReactComponent as UniqueSolutionPicture } from "./assets/svgs/uniqueSolutionPicture.svg";

import { ReactComponent as EliminateUnwantedBureaucracyPicture } from "./assets/svgs/eliminateUnwantedBureaucracyPicture.svg";

import { ReactComponent as DeviceBackMockup } from "./assets/svgs/deviceBackMockup.svg";

import { ReactComponent as ProcessDiagram } from "./assets/svgs/processDiagram.svg";

import navLogo from "./assets/images/GazindoLogo&Text.png";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/impressum">
          <Impressum />
        </Route>
        {/* <Route path="/login">
          <ComingSoon />
        </Route>
        <Route path="/signup">
          <ComingSoon />
        </Route>
        <Route path="/faq">
          <Faq />
        </Route>
        */}
        <Route path="/datenschutz">
          <Datenschutz />
        </Route>
        {/*
        <Route path="/impressum">
          <Impressum />
        </Route>
        <Route path="/geschaftsbedingungen">
          <Geschaftsbedingungen />
        </Route> */}
      </Switch>
    </Router>
  );
}

function Home() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const notifySuccess = () =>
    {
      console.log("success")
    toast.success("Email sent!", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  }
  const notifyError = () =>
    toast.error("Oops! Something went wrong...", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });

  const form = useRef();
  const sendContactFormEmail = (e) => {
    e.preventDefault();
    console.log(form.current)
    emailjs
      .sendForm('service_4bqz4ce', 'contact_form', form.current, 'Xlu8yl9RuUl2lwULL')
      .then(
        result => {
          notifySuccess();
          console.log(result.text);
        },
        error => {
          notifyError();
          console.log(error.text);
        }
      );
  }

  return (
    <div className="App" id="landing-page">
      <section id="hero-section">
        <Navbar fixed="top" light expand="md">
          <NavbarBrand href="/">
            <img className="" src={navLogo} alt="Gazindo" />
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <Link
                  to="hero-section"
                  spy={true}
                  smooth={true}
                  offset={-15}
                  duration={500}
                >
                  <NavLink href="/">Home</NavLink>
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to="unique-solutions-section"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  <NavLink href="/">Unique solution</NavLink>
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to="features-section"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  <NavLink href="/">Features</NavLink>
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to="cross-platform-container"
                  spy={true}
                  smooth={true}
                  offset={-75}
                  duration={500}
                >
                  <NavLink href="/">Cross-platform</NavLink>
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to="about-us-section"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  <NavLink href="/">About</NavLink>
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to="contact-section"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  <NavLink href="/">Contact</NavLink>
                </Link>
              </NavItem>
            </Nav>
              <NavLink href="http://46.101.165.108" id="signup-btn">
                Try it out
              </NavLink>
          </Collapse>
        </Navbar>
        <Carousel
          additionalTransfrom={0}
          arrows={false}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          centerMode={false}
          className="hide-on-mobile"
          containerClass="container"
          dotListClass=""
          draggable
          focusOnSelect={false}      
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          partialVisible
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024
              },
              items: 1,
              partialVisibilityGutter: 40
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0
              },
              items: 1,
              partialVisibilityGutter: 35
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464
              },
              items: 2,
              partialVisibilityGutter: 30
            }
          }}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={true}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          <Container id="hero-container">
            <Row>
              <Col id="hero-text-1">
                <div>
                  <h1 className="mb-0">Overview<br/>and Analysis</h1>
                  <p>Get an easy and quick overview<br/> of your business activities<br/>and your spendings.</p>
                </div>
              </Col>
            </Row>
          </Container>
          <Container id="hero-container">
            <Row>
              <Col></Col>
              <Col id="hero-text-1">
                <div>
                  <h1 className="mb-0">Digital Purchasing<br/> and Material Control</h1>
                  <p>Digital ordering, cost calculation, paperless receiving and<br/> invoicing, analytics and recipes.</p>
                </div>
              </Col>
            </Row>
          </Container>
          <Container id="hero-container">
            <Row>
              <Col id="hero-text-1">
                <div>
                  <h1 className="mb-0">iOS, Web,<br/> and Android</h1>
                  <p>Experience full capabilities of the<br/> Gazindo system on your favorite<br/> platform</p>
                </div>
              </Col>
            </Row>
          </Container>
        </Carousel>
        <Container className="hide-on-desktop" id="hero-container">
            <Row>
              <Col id="hero-text-1">
                <div>
                  <h1 className="mb-0">Overview<br/>and Analysis</h1>
                  <p>Get an easy and quick overview<br/> of your business activities<br/>and your spendings.</p>
                </div>
              </Col>
            </Row>
          </Container>
      </section>
      <section id="gastro-efficiency-solutions-section">
        <Container>
          <Row>
            <Col>
              <h2>
                Gastro Efficiency Solutions
              </h2>
              <p className="fs-20">
                Gazindo is a complete digital purchasing and material control system for IOS, Android tablets or PC.<br/>
                It combines the daily processes of quotations, ordering, receiving and stock taking for the hotel,<br/>
                restaurant and catering business in one single platform.
              </p>
              <Button>
                <Link
                  to="contact-section"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={500}
                >
                  CONTACT US NOW
                </Link>
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="unique-solutions-section">
        <Container>
          <Row>
            <Col sm={6} id="unique-solutions-picture-col" className="px-0">
              <UniqueSolutionPicture id="unique-solutions-picture"/>
            </Col>
            <Col sm={6} id="unique-solutions-text-col">
              <h3 className="mb-4">
                Unique Solutions
              </h3>
              <Row>
                <Col sm={2}>
                  <Icon1 />
                </Col>
                <Col>
                  <p className="fs-16 semi-bold mb-0">
                    <b>
                      Fully Digital
                    </b>
                  </p>
                  <p className="fs-16 mb-0">
                    We provide the complete solution for all your daily ordering routines, including digital delivery sheets and invoices.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={2}>
                  <Icon2 />
                </Col>
                <Col>
                  <p className="fs-16 semi-bold mb-0">
                    <b>
                      Direct Shopping or Tenders
                    </b>
                  </p>
                  <p className="fs-16 mb-0">
                    Gazindo's unique features let you buy either directly or to create tender
                    and let suppliers bid for the best prices.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={2}>
                  <Icon3 />
                </Col>
                <Col>
                  <p className="fs-16 semi-bold mb-0">
                    <b>
                      Analytics
                    </b>
                  </p>
                  <p className="fs-16">
                    Review key aspects of your business in an easy and effective way.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={2}>
                  <Icon4 />
                </Col>
                <Col>
                  <p className="fs-16 semi-bold mb-0">
                    <b>
                      Web, Android and iOS
                    </b>
                  </p>
                  <p className="fs-16">
                    Completely platform independent! Use all features on your Android phone
                    or tablet, iPhone/iPad, Mac or PC.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="eliminate-unwanted-bureaucracy-section">
        <Container>
          <Row>
            <Col id="eliminate-unwanted-bureaucracy-text-col">
              <h2 className="mb-0">
                Eliminate Unwanted Bureaucracy
              </h2>
              <p className="fs-20">
                No matter if you are a chef, a supplier, a delivery person, an accounting employee or a manager, Gazindo let's you automate most of the bureaucracy work - being that ordering goods, invoice filling, special offers management, recipe price calculation or cost and spending analysis.
              </p>
            </Col>
            <Col className="hide-on-mobile" sm={5}>
              <EliminateUnwantedBureaucracyPicture />
            </Col>
          </Row>
        </Container>
      </section>
      <section id="features-section">
        <Container>
          <Row>
            <Col>
              <h2 className="mb-4">
                Gazindo Features
              </h2>
              <Row>
                <Col className="feature-col">
                  <Row>
                    <Col sm={2}>
                      <FeatureIcon1 />
                    </Col>
                    <Col className="align-left">
                      <p className="fs-18 semi-bold mb-0">
                        <b>
                          Best prices
                        </b>
                      </p>
                      <p className="fs-14">
                        No more need to check every catalogue or contact every supplier. Our proprietary algorithms will pick the best prices and supplier for you automatically.
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col className="feature-col">
                  <Row>
                    <Col sm={2}>
                      <FeatureIcon2 />
                    </Col>
                    <Col className="align-left">
                      <p className="fs-18 semi-bold mb-0">
                        <b>
                          Tender
                        </b>
                      </p>
                      <p className="fs-14">
                        Ordering something special or just want to let suppliers bid? Create a tender and let suppliers place their offers.
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col className="feature-col">
                  <Row>
                    <Col sm={2}>
                      <FeatureIcon3 />
                    </Col>
                    <Col className="align-left">
                      <p className="fs-18 semi-bold mb-0">
                        <b>
                          Paperless
                        </b>
                      </p>
                      <p className="fs-14">
                        Eliminate most of your paperwork. Your orders, delivery sheets, invoices, product offers and business analytics are now fully digitalized.
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col className="feature-col hide-on-desktop">
                  <Row>
                    <Col sm={2}>
                      <FeatureIcon4 />
                    </Col>
                    <Col className="align-left">
                      <p className="fs-18 semi-bold mb-0">
                        <b>
                          HACCP
                        </b>
                      </p>
                      <p className="fs-14">
                        Track delivery time, person, vehicle, food temperature, quality, quantity and expiry date for each order.
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col className="feature-col hide-on-mobile">
                  <Row>
                    <Col sm={2}>
                      <FeatureIcon4 />
                    </Col>
                    <Col className="align-left">
                      <p className="fs-18 semi-bold mb-0">
                        <b>
                          HACCP
                        </b>
                      </p>
                      <p className="fs-14">
                        Track delivery time, person, vehicle, food temperature, quality, quantity and expiry date for each order.
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col className="feature-col">
                  <Row>
                    <Col sm={2}>
                      <FeatureIcon5 />
                    </Col>
                    <Col className="align-left">
                      <p className="fs-18 semi-bold mb-0">
                        <b>
                          Analytics
                        </b>
                      </p>
                      <p className="fs-14">
                        Simple but flexible analytics tools that can provide you detailed insight into your spending or earnings.
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col className="feature-col">
                  <Row>
                    <Col sm={2}>
                      <FeatureIcon6 />
                    </Col>
                    <Col className="align-left">
                      <p className="fs-18 semi-bold mb-0">
                        <b>
                          Special Offers
                        </b>
                      </p>
                      <p className="fs-14">
                        Get notified about the newest and hottest offers and from your suppliers the moment they appear.
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col className="feature-col">
                  <Row>
                    <Col sm={2}>
                      <FeatureIcon7 />
                    </Col>
                    <Col className="align-left">
                      <p className="fs-18 semi-bold mb-0">
                        <b>
                          User Management
                        </b>
                      </p>
                      <p className="fs-14">
                        Each user inside an organization has his personal account with custom permission levels.
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col className="feature-col">
                  <Row>
                    <Col sm={2}>
                      <FeatureIcon8 />
                    </Col>
                    <Col className="align-left">
                      <p className="fs-18 semi-bold mb-0">
                        <b>
                          Allergens & Additives
                        </b>
                      </p>
                      <p className="fs-14">
                        Get an easy access to the complete product sheet with list of product allergens and additives.
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col className="feature-col">
                  <Row>
                    <Col sm={2}>
                      <FeatureIcon9 />
                    </Col>
                    <Col className="align-left">
                      <p className="fs-18 semi-bold mb-0">
                        <b>
                          Recipes
                        </b>
                      </p>
                      <p className="fs-14">
                        Your restaurant's menu, digitalized for internal purposes. Check preparation directions, price estimate, pictures, etc.
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col className="feature-col hide-on-desktop">
                  <Row>
                    <Col sm={2}>
                      <FeatureIcon10 />
                    </Col>
                    <Col className="align-left">
                      <p className="fs-18 semi-bold mb-0">
                        <b>
                          Chat & Messaging Function
                        </b>
                      </p>
                      <p className="fs-14">
                        Direct and istant communication between you and the supplier.
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                
                </Col>
                <Col className="feature-col hide-on-mobile">
                  <Row>
                    <Col sm={2}>
                      <FeatureIcon10 />
                    </Col>
                    <Col className="align-left">
                      <p className="fs-18 semi-bold mb-0">
                        <b>
                          Chat & Messaging Function
                        </b>
                      </p>
                      <p className="fs-14">
                        Direct and istant communication between you and the supplier.
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col>
                
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <div id="cross-platform-container">
          <Row>
            <Col sm={6}>
              <DeviceBackMockup />
            </Col>
            <Col id="cross-platform-text-col">
              <h3 className="mb-0">
                Cross-Platfrom
              </h3>
              <p className="fs-20">
                Gazindo is seamlessly integrated through all<br/>popular platform and devices.<br/>You can now experience the full range of features<br className="hide-on-mobile"/>whether you are coming from Android, iOS or Web.
              </p>
            </Col>
          </Row>
        </div>
      </section>
      {/* <section id="cross-platform-section">
        <Container>
          
        </Container>
      </section> */}
      <section id="our-process-section">
        <Container>
          <Row>
            <Col>
              <h2 className="mb-0">
                Our Process
              </h2>
              <p className="fs-20 mb-3">
                Just do a few simple steps and let Gazindo take care of the rest.
              </p>
              <ProcessDiagram />
            </Col>
          </Row>
        </Container>
      </section>
      <section id="about-us-section">
        <Container>
          <Row>
            <Col>
              <h2 className="mb-0">
                About Us
              </h2>
              <p className="fs-20">
                We are a team of highly experienced professionals from hospitality and IT industries.
                Our mission is to fully digitalize the way hospitality industry works by providing complete solutions for purchasing, material control, cost control, accounting and business analytics.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="contact-section">
        <Container>
          <Row>
            <Col className="hide-on-mobile">
              
            </Col>
            <Col id="contact-section-form-col">
              <h2 className="mb-3">
                Contact
              </h2>
              <h3>
                Send Us a Message!
              </h3>
              <form ref={form} onSubmit={sendContactFormEmail}>
                <Row>
                  <Col>
                    <input required type="text" name="from_name" placeholder="Name" />
                  </Col>
                  <Col>
                    <input required type="email" name="from_email" placeholder="Email" />
                  </Col>
                </Row>
                <input required type="text" name="subject" placeholder="Subject" />
                <textarea required name="message" placeholder="Message" rows="8"  />
                <input type="submit" value="Send Message" />
                <ToastContainer />
              </form>
            </Col>
          </Row>
        </Container>
      </section>
      <footer>
        <Row>
          <Col>
            <Row>
              <Col className="full-width-col">
                <h5>Gazindo UG</h5>
                <div className="hide-on-desktop">
                  <p>Burggrafenstrasse 16</p>
                  <p>10787 Berlin</p>
                  <p>info@gazindo.com</p>
                </div>
                <div className="hide-on-desktop mt-4">
                  <p className="mb-0"><a href="/impressum">Impressum</a></p>
                </div>
              </Col>
              <Col className="full-width-col hide-on-mobile">
                <h5>Navigation</h5>
                <Link
                  to="hero-section"
                  spy={true}
                  smooth={true}
                  offset={150}
                  duration={500}
                >
                  <p>Home</p>
                </Link>
                <Link
                  to="product-showcase-section"
                  spy={true}
                  smooth={true}
                  // offset={-70}
                  duration={500}
                >
                  <p>Unique solution</p>
                </Link>
                <Link
                  to="how-it-works-section"
                  spy={true}
                  smooth={true}
                  // offset={-70}
                  duration={500}
                >
                  <p>Features</p>
                </Link>
                <Link
                  to="farm-map-section"
                  spy={true}
                  smooth={true}
                  // offset={-70}
                  duration={500}
                >
                  <p>Cross-platform</p>
                </Link>
                <Link
                  to="farm-map-section"
                  spy={true}
                  smooth={true}
                  // offset={-70}
                  duration={500}
                >
                  <p>About</p>
                </Link>
                <Link
                  to="farm-map-section"
                  spy={true}
                  smooth={true}
                  // offset={-70}
                  duration={500}
                >
                  <p>Contact</p>
                </Link>
              </Col>
              <Col className="full-width-col hide-on-mobile">
                <h5>Other</h5>
                <p><a href="/impressum">Impressum</a></p>
                {/* <p><a href="/faq">FAQ</a></p> */}
                <p><a href="/datenschutz">Datenschutz</a></p>
                {/* 
                <p><a href="/impressum">Impressum</a></p>
                <p><a href="/geschaftsbedingungen">Geschäftsbedingungen</a></p>
              </Col>
              <Col className="full-width-col">
                <h5>Contact</h5>
                <p>Hellofarmer UG</p>
                <p>Burggrafenstrasse 16</p>
                <p>10787 Berlin</p>
                <p>info@hellofarmer.de</p>
              </Col>
            </Row> */}
            {/* <Row>
              <Col className="footer-socials">
                <a href="/">
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
                <a href="/">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a href="/">
                  <FontAwesomeIcon icon={faLinkedin} />
                </a>
                <a href="/">
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a href="/">
                  <FontAwesomeIcon icon={faYoutube} />
                </a>
              </Col>
            </Row> */}
            {/* Remove margin after adding more elements to footer */}
            {/* <Row className="mb-3">
              <Col>
                <p id="copyright">© 2021 HelloFarmer UG, all rights reserved</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </footer>
    </div>
  );
}

function Faq() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
   
  return (
    <div className="App">
      <section id="hero-section">
        <Navbar light expand="md">
          <NavbarBrand href="/">
            <img className="" src={navLogo} alt="Bautory" />
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <Link
                  to="hero-section"
                  spy={true}
                  smooth={true}
                  offset={150}
                  duration={500}
                >
                  <NavLink href="/services">Leistungen</NavLink>
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to="product-showcase-section"
                  spy={true}
                  smooth={true}
                  // offset={-70}
                  duration={500}
                >
                  <NavLink href="/product">Produkt</NavLink>
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to="how-it-works-section"
                  spy={true}
                  smooth={true}
                  // offset={-70}
                  duration={500}
                >
                  <NavLink href="/how-it-works">Wie es funktioniert</NavLink>
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to="farm-map-section"
                  spy={true}
                  smooth={true}
                  // offset={-70}
                  duration={500}
                >
                  <NavLink href="/farm-map">Hofkarte</NavLink>
                </Link>
              </NavItem>
            </Nav>
            <NavLink href="https://app.hellofarmer.de/login">Anmeldung / Gast</NavLink>
            <NavLink id="signup-btn" href="https://app.hellofarmer.de/register">
              Registrieren
            </NavLink>
          </Collapse>
        </Navbar>
        <Container>
          <Row>
            <Col>
              
            </Col>
          </Row>
        </Container>
      </section>
      <footer className="dark-grey">
        <Row>
          <Col>
            <Row>
              <Col className="footer-img">
                <img className="ml-3 mb-2" src={navLogo} alt="HelloFarmer" />
              </Col>
            </Row>
            <Row>
              <Col className="full-width-col">
                <p className="footer-text">Wir bringen regionale Bio-Produkte direkt per Klick zu Ihnen auf den Tisch</p>
              </Col>
              <Col className="full-width-col">
                <h5>Navigation</h5>
                <Link
                  to="hero-section"
                  spy={true}
                  smooth={true}
                  offset={150}
                  duration={500}
                >
                  <p>Leistungen</p>
                </Link>
                <Link
                  to="product-showcase-section"
                  spy={true}
                  smooth={true}
                  // offset={-70}
                  duration={500}
                >
                  <p>Produkt</p>
                </Link>
                <Link
                  to="how-it-works-section"
                  spy={true}
                  smooth={true}
                  // offset={-70}
                  duration={500}
                >
                  <p>Wie es funkioniert</p>
                </Link>
                <Link
                  to="farm-map-section"
                  spy={true}
                  smooth={true}
                  // offset={-70}
                  duration={500}
                >
                  <p>Hofkarte</p>
                </Link>
              </Col>
              <Col className="full-width-col">
                <h5>Other</h5>
                {/* <p><a href="/faq">FAQ</a></p> */}
                {/* <p><a href="/datenschutz">Datenschutz</a></p>
                <p><a href="/impressum">Impressum</a></p>
                <p><a href="/geschaftsbedingungen">Geschäftsbedingungen</a></p> */}
              </Col>
              <Col className="full-width-col hide-on-mobile">
                <h5>Contact</h5>
                <p>Gazindo UG</p>
                <p>Burggrafenstrasse 16</p>
                <p>10787 Berlin</p>
                <p>info@gazindo.com</p>
              </Col>
            </Row>
            {/* <Row>
              <Col className="footer-socials">
                <a href="/">
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
                <a href="/">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a href="/">
                  <FontAwesomeIcon icon={faLinkedin} />
                </a>
                <a href="/">
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a href="/">
                  <FontAwesomeIcon icon={faYoutube} />
                </a>
              </Col>
            </Row> */}
            {/* Remove margin after adding more elements to footer */}
            <Row className="mb-3">
              <Col>
                <p id="copyright">© 2021 Gazindo UG, all rights reserved</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </footer>
    </div>
  );
}

function Datenschutz() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
   
  return (
    <div className="App" id="datenchutz">
      <section id="hero-section">
        <Navbar fixed="top" light expand="md">
          <NavbarBrand href="/">
            <img className="" src={navLogo} alt="Gazindo" />
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <Link
                  to="hero-section"
                  spy={true}
                  smooth={true}
                  offset={-15}
                  duration={500}
                >
                  <NavLink href="/">Home</NavLink>
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to="unique-solutions-section"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  <NavLink href="/">Unique solution</NavLink>
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to="features-section"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  <NavLink href="/">Features</NavLink>
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to="cross-platform-container"
                  spy={true}
                  smooth={true}
                  offset={-75}
                  duration={500}
                >
                  <NavLink href="/">Cross-platform</NavLink>
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to="about-us-section"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  <NavLink href="/">About</NavLink>
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to="contact-section"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  <NavLink href="/">Contact</NavLink>
                </Link>
              </NavItem>
            </Nav>
              <NavLink href="http://46.101.165.108" id="signup-btn">
                Try it out
              </NavLink>
          </Collapse>
        </Navbar>
        <Container>
          <Row>
            <Col>
              <p>1. Verantwortliche Stelle und Kontaktdaten des Datenschutzbeauftragten</p>
              <p>Verantwortlich für alle Verarbeitungen personenbezogener Daten in Deutschland sind wir, die Gazindo UG.. Per Mail erreichst Du uns unter info@gazindo.com Unseren Datenschutzbeauftragten erreichst Du unter info@gazindo.com.</p>
              <br/>
              <p>2. Zwecke und Rechtsgrundlagen der Datenverarbeitungen</p>
              <p>Alle Daten, die wir von dir erheben, erheben wir zu einem bestimmten Zweck. Nachfolgend findest du alle Zwecke und Kategorien von Daten, die wir für den jeweiligen Zweck erheben.</p>
              <br/>
              <p>2.1. Automatisch erhobene Zugriffsdaten</p>
              <p>Wenn du unsere Website besuchst, erheben wir die Zugriffsdaten, die dein Browser automatisch übermittelt, um dir den Besuch der Website zu ermöglichen. Die Zugriffsdaten umfassen insbesondere die IP-Adresse des anfragenden Geräts, Datum und Uhrzeit der Anfrage, Adresse der aufgerufenen Website und der anfragenden Website, Technische Angaben über den verwendeten Browser und das Betriebssystem sowie Online-Kennungen (z. B. Gerätekennungen, Session-IDs).</p>
              <p>Bei der bloß informatorischen Nutzung der App, also noch vor der Registrierung, erheben wir nur die personenbezogenen Daten, die für die Nutzung der App erforderlich sind. Hierzu gehören</p>
              <p>Geräte- und Kartenkennungen (DeviceID, IMEI, IMSI, MSISDN, MAC-Adresse, Name des mobilen Endgerätes), IP-Adresse, Betriebssystem, Datum und Uhrzeit</p>
              <p>Die Verarbeitung dieser Zugriffsdaten erfolgt auf Grund unserer berechtigten Interessen: Den Besuch der Website oder App zu ermöglichen, die dauerhafte Funktionsfähigkeit und Sicherheit unserer Systeme zu gewährleisten sowie um unsere Website und Apps allgemein administrativ zu pflegen.</p>
              <br/>
              <p>2.2. Kontaktaufnahme</p>
              <p>Du hast die Möglichkeit über verschiedene Kanäle mit uns in Kontakt zu treten. In diesem Zusammenhang verarbeiten wir deine personenbezogenen Daten ausschließlich zum Zwecke der Kommunikation mit dir. Entweder, um einen Vertrag mit dir zu erfüllen oder anzubahnen, oder auf Grund unseres berechtigten Interesses deine Anfrage beantworten zu können.</p>
              <br/>
              <p>2.3. Registrierung und Login</p>
              <p>Du hast die Möglichkeit, dich für unseren Login-Bereich zu registrieren, um den vollen Funktionsumfang unserer Dienste nutzen zu können. Die von dir mindestens anzugebenden Daten (Vor- und Nachname, E-Mail-Adresse, Telefonnummer, Passwort) haben wir durch eine entsprechende Kennzeichnung als „Pflichtfelder“ hervorgehoben. Ohne diese Daten ist eine Registrierung nicht möglich. Wir verarbeiten diese Daten, um einen Vertrag mit Dir zu erfüllen oder anzubahnen und im Übrigen auf Grund unseres berechtigten Interesses dir das Anlegen eines Kontos zu ermöglichen und andere damit verbundene Dienstleistungen zu erbringen, die in dieser Datenschutzerklärung benannt sind.</p>
              <br/>
              <p>2.4. Bestellungen</p>
              <p>Tätigst du eine Bestellung, erheben wir insoweit die für die Vertragsabwicklung notwendigen Pflichtangaben. Dazu gehören Vor- und Nachname, E-Mail-Adresse, Telefonnummer, Passwort und die angegebenen Lieferadressen. Wir verarbeiten diese Daten, um einen Vertrag mit dir zu erfüllen</p>
              <p>Zur Durchführung der Zahlungsabwicklung (z.B. SEPA-Lastschrifteinzug, PayPal) setzen wir den Dienstleister Stripe Deutschland GmbH ,Prielmayerstraße 3, 80335 München („Stripe“) ein, an den wir deine im Rahmen des Bestellvorgangs mitgeteilten Informationen nebst den Informationen über deine Bestellung (Name, Anschrift, IBAN, BIC, Rechnungsbetrag, Währung und Transaktionsnummer) zur Vertragserfüllung weitergeben. Die Weitergabe deiner personenbezogenen Daten erfolgt ausschließlich zum Zwecke der Zahlungsabwicklung mit Stripe</p>
              <br/>
              <p>2.5. Produktempfehlungen und Bestandskundenwerbung</p>
              <p>Auf Grundlage der Kaufhistorie deiner bestellten Produkte und deiner favorisierten Produkte können wir dir ggf. passende Produktempfehlungen für gleiche oder ähnliche Produkte per E-Mail, Push- Benachrichtigung, Pop-up-Nachricht sowie innerhalb der Website / App anbieten.</p>
              <p>Für Push-Benachrichtigungen und Pop-up-Nachrichten ist die Rechtsgrundlage deine Einwilligung. Rechtsgrundlage für Produktempfehlungen innerhalb der Website ist unser berechtigtes Interesse, dir Produktempfehlungen zum Zwecke der Direktwerbung anzuzeigen.</p>
              <p>Soweit wir dir E-Mails und/oder SMS mit Produktempfehlungen aufgrund deiner bei uns getätigten Käufe schicken, erfolgt die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO i. V. m. § 7 Abs. 3 UWG („Bestandskundenwerbung“). Der werblichen Nutzung deiner Daten kannst du jederzeit mittels eines entsprechenden Links in den E-Mails bzw. einer Nachricht per SMS oder durch Mitteilung an die oben genannten Kontaktdaten (z. B. per E-Mail oder Brief) widersprechen, ohne dass dir hierfür andere als die Übermittlungskosten nach den Basistarifen entstehen.</p>
              <br/>
              <p>3. Einsatz von Cookies und vergleichbaren Technologien</p>
              <p>Wir verwenden Cookies und vergleichbare Technologien (zusammengefasst „Tools“), die entweder von uns selbst oder von Dritten angeboten werden.</p>
              <p>Wir haben unsere Tools in die Kategorien Essenziell, Funktional, Marketing und Analyse sowie Werbung eingeteilt.</p>
              <p>Tools der Kategorie Essenziell sind standardmäßig aktiviert und können nicht deaktiviert werden, da Du unsere Dienste ohne die essenziellen Tools nicht nutzen kannst. Alle weiteren Cookies werden nur mit Deiner Einwilligung, eingesetzt. Diese Einwilligung kannst Du hier erteilen und auch wieder widerrufen.</p>
              <br/>
              <p>3.1. Essenzielle Tools</p>
              <p>Wir verwenden bestimmte Tools, um die grundlegenden Funktionen unserer Dienste zu ermöglichen („notwendige Tools“). Ohne diese Tools könnten wir unseren Dienst nicht zur Verfügung stellen. Deshalb werden notwendige Tools ohne Einwilligung aufgrund unserer berechtigten Interessen, zur Erfüllung eines Vertrags oder zur Durchführung vorvertraglicher Maßnahmen verwendet.</p>
              <br/>
              <p>3.1.1. Eigene Cookies</p>
              <p>Wir speichern zur Bereitstellung der grundlegenden Funktionen unserer Dienste insbesondere folgende Informationen in eigenen notwendigen Cookies: Login-Authentifizierung, Sprache, Warenkorbinhalte, Checkout-Felder für die Durchführung der Bestellung, ausgewählte/eingegebene Adresse, Standortdaten des Geräts, ausgewählte Zahlungsmethode, Bestell-Historie und Such- Historie.</p>
              <br/>
              <p>3.2. – Here we need to add the 3rd party services:</p>
              <br/>
              <p>3.3. Marketing und Analysetools</p>
              <p>Um unsere Website zu verbessern, verwenden wir Tools zur statistischen Erfassung und Analyse des allgemeinen Nutzungsverhaltens anhand von Zugriffsdaten („Analyse-Tools“). Ferner nutzen wir Analysedienste, um die Nutzung unserer verschiedenen Marketingkanäle auszuwerten.</p>
              <p>Rechtgrundlage für die Analyse-Tools ist eine ausdrücklich von dir erteilte Einwilligung</p>
              <br/>
              <p>3.3.1. Google Analytics</p>
              <p>Unsere Website verwendet den Webanalysedienst Google Analytics, der für Nutzer aus Europa, dem Nahen Osten und Afrika (EMEA) von der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland angeboten wird und für alle übrigen Nutzer von der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (zusammen „Google“). Wir binden Google Analytics mithilfe des Tools Segment in unsere Website ein (siehe Ziffer 3.4.1).</p>
              <p>Google Analytics verwendet Cookies und ähnliche Technologien, um unsere Website anhand deines Nutzerverhaltens zu analysieren und verbessern zu können. Google verarbeitet die Daten, um Reports über die Websiteaktivitäten für die Websitebetreiber zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen. Deine IP-Adresse wird vor der Weiterverarbeitung anonymisiert, sodass keine Rückschlüsse auf deine Identität möglich sind.</p>
              <p>Insbesondere folgende Daten können von Google Analytics verarbeitet werden: Anonymisierte IP- Adresse, Referrer-URL (zuvor besuchte Seite), Aufgerufene Seiten (Datum, Uhrzeit, URL, Titel, Verweildauer), Heruntergeladene Dateien, Angeklickte Links zu anderen Websites, ggf. Erreichung von bestimmten Zielen (Conversions), Technische Informationen: Betriebssystem; Browsertyp, -version und –sprache; Gerätetyp, -marke, -modell und -auflösung sowie dein ungefährer Standort (Land und ggf. Stadt, ausgehend von anonymisierter IP-Adresse).</p>
              <p>Die Datenverarbeitung von Google Analytics findet möglicherweise auch auf Servern in den USA statt. Wir haben mit Google zur Nutzung von Google Analytics einen Auftragsverarbeitungsvertrag sowie für den Fall, dass personenbezogene Daten in die USA oder andere Drittländer übertragen werden, sog. Standardvertragsklauseln abgeschlossen.</p>
              <p>Nähere Informationen findest du auch in der Datenschutzerklärung von Google.</p>
              <br/>
              <p>3.4. Werbetools</p>
              <p>Wir verwenden weiterhin Tools zu Werbezwecken („Marketing-Tools“). Einige der bei der Nutzung unserer Website anfallenden Zugriffsdaten werden für interessenbezogene Werbung genutzt. Durch die Analyse und Auswertung dieser Zugriffsdaten ist es uns möglich, personalisierte Werbung, also solche, die deinen tatsächlichen Interessen und Bedürfnissen entspricht, auf unserer Website und auf den Websites und Apps von anderen Anbietern darzustellen.</p>
              <p>Rechtgrundlage für solche Marketing-Tools ist deine ausdrückliche Einwilligung</p>
              <p>Im nachfolgenden Abschnitt möchten wir dir diese Technologien und die hierfür eingesetzten Anbieter näher erläutern. Zu den erhobenen Daten können insbesondere gehören: die IP-Adresse des Geräts, die Kennnummer eines Cookies, die Gerätekennung mobiler Geräte (Geräte-ID), Referrer-URL (zuvor besuchte Seite), Aufgerufene Seiten (Datum, Uhrzeit, URL, Titel, Verweildauer), Heruntergeladene Dateien, Angeklickte Links zu anderen Websites, ggf. Erreichung von bestimmten Zielen (Conversions), Technische Informationen: Betriebssystem; Browsertyp, -version und –sprache; Gerätetyp, -marke, - modell und -auflösung, Ungefährer Standort (Land und ggf. Stadt).</p>
              <p>Die erhobenen Daten werden jedoch ausschließlich pseudonym gespeichert, sodass keine unmittelbaren Rückschlüsse auf Personen möglich sind.</p>
              <br/>
              <p>3.4.1. Segment</p>
              <p>Wir verwenden den Dienst Segment der Segment Inc., 55 2nd St, 4th Fl., San Francisco, CA 94105, USA („Segment“). Segment ist unser zentrales Tool zur Erhebung und Auswertung von Nutzungsdaten sowie zum Ausspielen interessenbezogener personalisierter Werbung. Hierfür werden mithilfe von Segment alle weiteren unserer Tracking- und Marketing-Dienste eingebunden, insbesondere Google Analytics, Google Ads, Facebook-Pixel und Adjust. Die durch Segment erhobenen Daten werden an diese Dienste weitergegeben.</p>
              <p>Hierfür speichert Segment insbesondere folgende Informationen im Local Storage:</p>
              <p>Nähere Informationen sind der Dokumentation sowie der Datenschutzerklärung von Segment zu entnehmen.</p>
              <br/>
              <p>3.4.2. Facebook-Pixel</p>
              <p>Unsere Websites verwendet zu Marketingzwecken den Dienst „Facebook-Pixel“ des sozialen Netzwerks Meta, einem Dienst der für Nutzer außerhalb der USA und Kanada von der Facebook Platforms Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland und für alle übrigen Nutzer von der Facebook Inc., 1601 Willow Road, Menlo Park, California 94025, USA (zusammen „Facebook“) angeboten wird. Wir binden Facebook-Pixel mithilfe des Tools Segment in unsere Website ein (siehe Ziffer 3.4.1).</p>
              <p>Wir nutzen Facebook-Pixel, um dir anhand deiner Interessen für unsere Produkte individualisierte Werbebotschaften auszuspielen („Retargeting“). Dabei erfolgt auch ein Zielgruppen-Remarketing durch Custom Audience. Facebook verarbeitet dafür Daten, die der Dienst über Cookies, Web Beacons und vergleichbare Speichertechnologien auf unseren Websites erhebt.</p>
              <p>Die in diesem Zusammenhang anfallenden Daten können von Facebook zur Auswertung an einen Server in den USA übertragen und dort gespeichert werden. Für den Fall, dass personenbezogene Daten in die USA übertragen werden, haben wir mit Facebook sog. Standardvertragsklauseln abgeschlossen.</p>
              <p>Falls du Mitglied bei Facebook bist und du Facebook über die Privatsphären-Einstellungen deines Kontos eine entsprechende Erlaubnis erteilt hast, kann Facebook die über deinen Besuch bei uns erfassten Information mit deinem Mitgliedskonto verknüpfen und zur zielgerichteten Schaltung von Facebook-Ads verwenden. Die Privatsphären-Einstellungen deines Facebook-Profils kannst du jederzeit einsehen und ändern.</p>
              <p>Wenn du der Verwendung von Facebook-Pixel nicht zugestimmt hast, wird Facebook nur noch generelle Facebook-Ads anzeigen, die nicht anhand der über dich erfassten Informationen auf dieser Website ausgewählt werden.</p>
              <p>Nähere Informationen hierzu findest du in den Datenschutzhinweisen von Facebook.</p>
              <br/>
              <p>3.4.3. Google Ads-Conversion-Tracking und Ads-Remarketing (ehemals AdWords)</p>
              <p>Unsere Websites nutzt den Dienst „Google Ads“, der für Nutzer aus dem Europäischen Wirtschaftsraum und der Schweiz von der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland und für alle übrigen Nutzer von Google LLC 1600 Amphitheatre Parkway Mountain View, CA 94043, USA (zusammen „Google“) angeboten werden. Wir binden Google Ads mithilfe des Tools Segment in unsere Website ein (siehe Ziffer 3.4.1).</p>
              <p>Bei Google Ads werden mittels „Google Ads-Conversion-Tracking“ von uns definierte Kundenaktionen erfasst (wie beispielweise das Klicken auf eine Anzeige, Seitenaufrufe, Downloads) und analysiert. „Google Ads-Remarketing“ nutzen wir, um dir individualisierte Werbebotschaften für unsere Produkte auf Partnerwebsites von Google anzuzeigen. Beide Dienste setzen dafür Cookies und ähnliche Technologien ein.</p>
              <p>Die in diesem Zusammenhang anfallenden Daten können von Google zur Auswertung an einen Server in den USA übertragen und dort gespeichert werden. Für den Fall, dass personenbezogene Daten in die USA übertragen werden, haben wir mit Google sog. Standardvertragsklauseln abgeschlossen.</p>
              <p>Falls du ein Google-Konto verwendest, kann Google je nach den im Google-Konto hinterlegten Einstellungen deinen Web- und App-Browserverlauf mit deinem Google-Konto verknüpfen und Informationen aus deinem Google-Konto zum Personalisieren von Anzeigen verwenden. Wenn du eine solche Zuordnung zu deinem Google-Konto nicht wünschst, ist es erforderlich, dass du dich vor dem Aufruf unserer Website bei Google ausloggst.</p>
              <p>Wenn du der Verwendung von Google Ads nicht zugestimmt hast, wird Google nur noch generelle Werbung anzeigen, die nicht anhand der über dich erfassten Informationen auf dieser Website ausgewählt wurde. Neben dem Widerruf deiner Einwilligung hast du zudem die Möglichkeit, personalisierte Werbung in den Einstellungen für Werbung bei Google zu deaktivieren.</p>
              <p>Nähere Informationen findest du auch in den Hinweisen zur Datennutzung und der Datenschutzerklärung von Google.</p>
              <br/>
              <p>3.4.4. Adjust</p>
              <p>Wir verwenden den Dienst Adjust der Adjust GmbH, Saarbrücker Str. 37A, 10405 Berlin („Adjust“) zur Nutzungsanalyse und zur Optimierung unserer Marketingaktivitäten und -kampagnen. Hierzu gehört insbesondere die Auswertung deiner Interaktion mit der Webseite.</p>
              <p>Insbesondere folgende Daten werden durch Adjust verarbeitet: Anonymisierte („gehashte“) IP- Adresse, Geräte-ID, Werbe-ID und andere mobile Kennungen, Zeitpunkt des ersten Aufrufs der Website, Interaktion mit der Website (z.B. Registrierung), Informationen über gesehene oder angeklickte Werbeanzeigen.</p>
              <p>Die Daten werden durch Adjust ausschließlich in pseudonymisierter Form aufbereitet und auf Servern in Deutschland gespeichert. Nähere Informationen findest du in der Datenschutzerklärung von Adjust. Wir haben mit der Adjust GmbH eine Vereinbarung zur Auftragsverarbeitung geschlossen.</p>
              <br/>
              <p>3.4.5. TV Squared</p>
              <p>Um die Wirksamkeit und den Effekt von TV-Werbekampagnen auf das Nutzerverhalten zu messen, verwenden wir TVSquared. Dies ist ein Dienst von TV Squared Ltd, Codebase, Argyle House, 3 Lady Lawson Street, Edinburgh, EH3 9DR, Vereinigtes Königreich.</p>
              <p>TV Squared erhebt in unserem Auftrag Datum und Uhrzeit von Nutzersitzungen, verweisende Webseiten, Nutzerinteraktionen auf unseren Webseiten sowie Informationen über Browser und Betriebssystem. Zu diesem Zweck weist ein Cookie jedem Nutzer eine zufällige Nutzer-ID zu. Alle Daten werden von TV Squared in Großbritannien verarbeitet. Weitere Informationen findest Du in der Datenschutzerklärung von TV Squared. Du kannst dem setzen von Cookies durch TVSquared hier widersprechen.</p>
              <br/>
              <p>3.5. Google Maps</p>
              <p>Unsere Website verwendet den Kartendienst Google Maps, der für Nutzer aus dem Europäischen Wirtschaftsraum und der Schweiz von der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland und für alle übrigen Nutzer von der Google LLC 1600 Amphitheatre Parkway Mountain View, CA 94043, USA (zusammen „Google“) angeboten wird.</p>
              <p>Damit das von uns benutzte Google-Kartenmaterial eingebunden und in deinem Webbrowser angezeigt werden kann, muss dein Webbrowser beim Aufruf unserer Website eine Verbindung zu einem Server von Google, der sich auch in den USA befinden kann, aufnehmen.</p>
              <p>Google erhält durch die Einbindung des Kartenmaterials die Information, dass von der IP-Adresse deines Gerätes eine Seite unserer Website aufgerufen wurde. Wenn du den Google-Kartendienst auf unserer Website aufrufst, während du in deinem Google-Profil eingeloggt bist, kann Google dieses Ereignis zudem mit deinem Google-Profil verknüpfen. Wenn du die Zuordnung zu deinem Google-Profil nicht wünschst, ist es erforderlich, dass du dich vor dem Aufruf unserer Seite bei Google ausloggst. Google speichert deine Daten und nutzt diese für Zwecke der Werbung, Marktforschung und personalisierten Darstellung von Google Maps.</p>
              <p>Rechtsgrundlage ist unser berechtigtes Interesse, das darin besteht, durch eingebundene Karten dein Nutzungserlebnis zu verbessern.</p>
              <p>Nähere Informationen hierzu findest du auch in der Datenschutzerklärung von Google und den Zusätzlichen Nutzungsbedingungen für Google Maps.</p>
              <br/>
              <p>4. Weitergabe von Daten</p>
              <p>Eine Weitergabe der von uns erhobenen Daten erfolgt grundsätzlich nur, wenn du deine ausdrückliche Einwilligung dazu erteilt hast, die Weitergabe zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist und kein Grund zur Annahme besteht, dass du ein überwiegendes schutzwürdiges Interesse am Unterbleiben der Weitergabe deiner Daten hast, wir zur Weitergabe gesetzlich verpflichtet sind oder dies gesetzlich zulässig und für die Abwicklung von Vertragsverhältnissen mit dir oder für die Durchführung vorvertraglicher Maßnahmen erforderlich ist, die auf deine Anfrage hin erfolgen.</p>
              <p>Ein Teil der Datenverarbeitung kann durch unsere Dienstleister erfolgen. Hierzu können insbesondere Rechenzentren, die unsere Website und Datenbanken speichern, Softwareanbieter, IT-Dienstleister, die unsere Systeme warten, Agenturen, Marktforschungsunternehmen, weitere Gazindo Gesellschaften sowie Beratungsunternehmen gehören. Sofern wir personenbezogene Daten an unsere Dienstleister weitergeben, dürfen diese die Daten ausschließlich zur Erfüllung ihrer Aufgaben verwenden. Die Dienstleister wurden von uns sorgfältig ausgewählt und beauftragt. Sie sind vertraglich an unsere Weisungen gebunden, verfügen über geeignete technische und organisatorische Maßnahmen zum Schutz der Rechte der betroffenen Personen und werden von uns regelmäßig kontrolliert.</p>
              <p>Darüber hinaus kann eine Weitergabe in Zusammenhang mit behördlichen Anfragen, Gerichtsbeschlüssen und Rechtsverfahren erfolgen, wenn es für die Rechtsverfolgung oder - durchsetzung erforderlich ist.</p>
              <br/>
              <p>5. Datenübermittlung in Drittländer</p>
              <p>Teilweise setzen wir Dienste ein, deren Anbieter in sogenannten Drittländern (außerhalb der Europäischen Union bzw. des Europäischen Wirtschaftsraums) sitzen oder dort personenbezogene Daten verarbeiten, also Ländern, deren Datenschutzniveau nicht dem der Europäischen Union entspricht. Soweit dies der Fall ist und die Europäische Kommission für diese Länder keinen Angemessenheitsbeschluss (Art. 45 DSGVO) erlassen hat, haben wir entsprechende Vorkehrungen getroffen, um ein angemessenes Datenschutzniveau für etwaige Datenübertragungen zu gewährleisten. Hierzu zählen u.a. die sog. Standardvertragsklauseln der Europäischen Union oder verbindliche interne Datenschutzvorschriften.</p>
              <p>Wo dies nicht möglich ist, stützen wir die Datenübermittlung auf Ausnahmen des Art. 49 DSGVO, insbesondere auf deine ausdrücklich erteilte Einwilligung oder die Erforderlichkeit der Übermittlung zur Vertragserfüllung oder zur Durchführung vorvertraglicher Maßnahmen.</p>
              <br/>
              <p>6. Speicherdauer</p>
              <p>Grundsätzlich speichern wir personenbezogene Daten nur so lange, wie dies zur Erfüllung der Zwecke erforderlich, zu denen wir die Daten erhoben haben. Danach löschen wir die Daten unverzüglich, es sei denn, wir benötigen die Daten noch bis zum Ablauf der gesetzlichen Verjährungsfrist zu Beweiszwecken für zivilrechtliche Ansprüche oder wegen des Bestehens gesetzlicher Aufbewahrungspflichten</p>
              <p>Zu Beweiszwecken müssen wir Vertragsdaten noch drei Jahre ab Ende des Jahres, in dem die Geschäftsbeziehungen mit dir endet, aufbewahren. Etwaige Ansprüche verjähren nach der gesetzlichen Regelverjährungsfrist frühestens zu diesem Zeitpunkt.</p>
              <p>Auch danach müssen wir deine Daten teilweise noch aus buchhalterischen Gründen speichern. Wir sind dazu wegen gesetzlicher Dokumentationspflichten verpflichtet, die sich insbesondere aus dem Handelsgesetzbuch, der Abgabenordnung, dem Kreditwesengesetz, dem Geldwäschegesetz und dem Wertpapierhandelsgesetz ergeben können. Die dort vorgegebenen Fristen zur Aufbewahrung von Unterlagen betragen zwei bis zehn Jahre.</p>
              <br/>
              <p>7. Deine Rechte</p>
              <p>Wenn die gesetzlichen Voraussetzungen vorliegen, hast du uns gegenüber das Recht Auskunft darüber zu verlangen, welche personenbezogenen Daten wir von Dir verarbeiten. Du kannst zudem die Berichtigung, Löschung und/oder Einschränkung der Verarbeitung deiner personenbezogenen Daten beantragen und hast das Recht, deine personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten.</p>
              <p>Wenn wir deine personenbezogenen Daten auf Grundlage überwiegender berechtigter Interessen verarbeiten, hast Du das Recht, Widerspruch gegen die Verarbeitung deiner personenbezogenen Daten einzulegen. aus Gründen, die sich aus deiner besonderen Situation ergeben. Geht es um einen Widerspruch gegen die Datenverarbeitung zu Zwecken der Direktwerbung, hast du ein generelles Widerspruchsrecht, das auch ohne die Angabe von Gründen von uns umgesetzt wird.</p>
              <p>Du hast das Recht, eine einmal erteilte Einwilligung jederzeit uns gegenüber zu widerrufen. Dies hat zur Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruht, für die Zukunft nicht mehr fortführen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit, der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.</p>
              <p>Wenn Du der Meinung bist, dass wir deine Daten unrechtmäßig verarbeiten, hast du das Recht, dich bei einer Aufsichtsbehörde zu beschweren. Die für uns zuständige Aufsichtsbehörde ist die Berliner Beauftragte für Datenschutz und Informationsfreiheit, Friedrichstr. 219, 10969 Berlin.</p>
              <p>Etwaige Anfragen zur Geltendmachung von Datenschutzrechten und unsere Antworten darauf werden zu Dokumentationszwecken für die Dauer von bis zu drei Jahren und im Einzelfall zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen auch darüber hinaus aufbewahrt. Rechtsgrundlage hierfür ist unser Interesse an der Verteidigung gegen etwaige zivilrechtliche Ansprüche nach, der Vermeidung von Bußgeldern sowie der Erfüllung unserer Rechenschaftspflichten.</p>
              <br/>
              <p>8. Änderung der Datenschutzerklärung</p>
              <p>Gelegentlich aktualisieren wir diese Datenschutzerklärung, beispielsweise wenn wir unsere Website anpassen oder sich die gesetzlichen oder behördlichen Vorgaben ändern.</p>
            </Col>
          </Row>
        </Container>
      </section>
      <footer>
        <Row>
          <Col>
            <Row>
              <Col className="full-width-col">
                <h5>Gazindo UG</h5>
                <div className="hide-on-desktop">
                  <p>Burggrafenstrasse 16</p>
                  <p>10787 Berlin</p>
                  <p>info@gazindo.com</p>
                </div>
                <div className="hide-on-desktop mt-4">
                  <p className="mb-0"><a href="/impressum">Impressum</a></p>
                </div>
              </Col>
              <Col className="full-width-col hide-on-mobile">
                <h5>Navigation</h5>
                <Link
                  to="hero-section"
                  spy={true}
                  smooth={true}
                  offset={150}
                  duration={500}
                >
                  <p>Home</p>
                </Link>
                <Link
                  to="product-showcase-section"
                  spy={true}
                  smooth={true}
                  // offset={-70}
                  duration={500}
                >
                  <p>Unique solution</p>
                </Link>
                <Link
                  to="how-it-works-section"
                  spy={true}
                  smooth={true}
                  // offset={-70}
                  duration={500}
                >
                  <p>Features</p>
                </Link>
                <Link
                  to="farm-map-section"
                  spy={true}
                  smooth={true}
                  // offset={-70}
                  duration={500}
                >
                  <p>Cross-platform</p>
                </Link>
                <Link
                  to="farm-map-section"
                  spy={true}
                  smooth={true}
                  // offset={-70}
                  duration={500}
                >
                  <p>About</p>
                </Link>
                <Link
                  to="farm-map-section"
                  spy={true}
                  smooth={true}
                  // offset={-70}
                  duration={500}
                >
                  <p>Contact</p>
                </Link>
              </Col>
              <Col className="full-width-col hide-on-mobile">
                <h5>Other</h5>
                <p><a href="/impressum">Impressum</a></p>
                <p><a href="/datenschutz">Datenschutz</a></p>
              </Col>
              <Col className="full-width-col hide-on-mobile">
                <h5>Contact</h5>
                <p>Gazindo UG</p>
                <p>Burggrafenstrasse 16</p>
                <p>10787 Berlin</p>
                <p>info@gazindo.com</p>
              </Col>
            </Row>        
            <Row className="mb-3">
              <Col>
                <p id="copyright">© 2021 Gazindo UG, all rights reserved</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </footer>
    </div>
  );
}

function Impressum() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
   
  return (
    <div className="App" id="impressum-page">
      <section id="hero-section">
      <Navbar light expand="md">
          <NavbarBrand href="/">
            <img className="" src={navLogo} alt="Gazindo" />
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <Link
                  to="hero-section"
                  spy={true}
                  smooth={true}
                  offset={-15}
                  duration={500}
                >
                  <NavLink href="/">Home</NavLink>
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to="unique-solutions-section"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  <NavLink href="/">Unique solution</NavLink>
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to="features-section"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  <NavLink href="/">Features</NavLink>
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to="cross-platform-container"
                  spy={true}
                  smooth={true}
                  offset={-75}
                  duration={500}
                >
                  <NavLink href="/">Cross-platform</NavLink>
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to="about-us-section"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  <NavLink href="/">About</NavLink>
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to="contact-section"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  <NavLink href="/">Contact</NavLink>
                </Link>
              </NavItem>
            </Nav>
              <NavLink href="http://46.101.165.108" id="signup-btn">
                Try it out
              </NavLink>
          </Collapse>
        </Navbar>
        <Container>
          <Row>
            <Col>
              <h2>Impressum</h2>
              <p>Angaben gemäß § 5 TMG:<br/>Gazindo UG<br/>10787 Berlin<br/>Deutschland</p>
              <p>Vertreten durch:<br/>Matija Corkovic</p>
              <p>Kontakt:<br/>E-Mail: matija@gazindo.com</p>
              <p>Handelsregister:<br/>Amtsgericht Charlottenburg (Berlin) HRB 226441 B</p>
              <p>Umsatzsteuer-ID:<br/>Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz:<br/>DE343779509</p>
            </Col>
          </Row>
        </Container>
      </section>
      <footer>
        <Row>
          <Col>
            <Row>
              <Col className="full-width-col">
                <h5>Gazindo UG</h5>
                <div className="hide-on-desktop">
                  <p>Burggrafenstrasse 16</p>
                  <p>10787 Berlin</p>
                  <p>info@gazindo.com</p>
                </div>
              </Col>
              <Col className="full-width-col">
                <h5>Navigation</h5>
                <Link
                  to="hero-section"
                  spy={true}
                  smooth={true}
                  offset={150}
                  duration={500}
                >
                  <p>Home</p>
                </Link>
                <Link
                  to="product-showcase-section"
                  spy={true}
                  smooth={true}
                  // offset={-70}
                  duration={500}
                >
                  <p>Unique solution</p>
                </Link>
                <Link
                  to="how-it-works-section"
                  spy={true}
                  smooth={true}
                  // offset={-70}
                  duration={500}
                >
                  <p>Features</p>
                </Link>
                <Link
                  to="farm-map-section"
                  spy={true}
                  smooth={true}
                  // offset={-70}
                  duration={500}
                >
                  <p>Cross-platform</p>
                </Link>
                <Link
                  to="farm-map-section"
                  spy={true}
                  smooth={true}
                  // offset={-70}
                  duration={500}
                >
                  <p>About</p>
                </Link>
                <Link
                  to="farm-map-section"
                  spy={true}
                  smooth={true}
                  // offset={-70}
                  duration={500}
                >
                  <p>Contact</p>
                </Link>
              </Col>
              <Col className="full-width-col hide-on-mobile">
                <h5>Other</h5>
                <p><a href="/impressum">Impressum</a></p>
              </Col>
              <Col className="full-width-col hide-on-mobile">
                <h5>Contact</h5>
                <p>Gazindo UG</p>
                <p>Burggrafenstrasse 16</p>
                <p>10787 Berlin</p>
                <p>info@gazindo.com</p>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <p id="copyright">© 2021 Gazindo UG, all rights reserved</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </footer>
    </div>
  );
}

// function Geschaftsbedingungen() {
//   const [isOpen, setIsOpen] = useState(false);
//   const toggle = () => setIsOpen(!isOpen);
   
//   return (
//     <div className="App">
//       <section id="hero-section">
//         <Navbar light expand="md">
//           <NavbarBrand href="/">
//             <img className="" src={navLogo} alt="Bautory" />
//           </NavbarBrand>
//           <NavbarToggler onClick={toggle} />
//           <Collapse isOpen={isOpen} navbar>
//             <Nav className="mr-auto" navbar>
//               <NavItem>
//                 <Link
//                   to="hero-section"
//                   spy={true}
//                   smooth={true}
//                   offset={150}
//                   duration={500}
//                 >
//                   <NavLink href="/services">Leistungen</NavLink>
//                 </Link>
//               </NavItem>
//               <NavItem>
//                 <Link
//                   to="product-showcase-section"
//                   spy={true}
//                   smooth={true}
//                   // offset={-70}
//                   duration={500}
//                 >
//                   <NavLink href="/product">Produkt</NavLink>
//                 </Link>
//               </NavItem>
//               <NavItem>
//                 <Link
//                   to="how-it-works-section"
//                   spy={true}
//                   smooth={true}
//                   // offset={-70}
//                   duration={500}
//                 >
//                   <NavLink href="/how-it-works">Wie es funktioniert</NavLink>
//                 </Link>
//               </NavItem>
//               <NavItem>
//                 <Link
//                   to="farm-map-section"
//                   spy={true}
//                   smooth={true}
//                   // offset={-70}
//                   duration={500}
//                 >
//                   <NavLink href="/farm-map">Hofkarte</NavLink>
//                 </Link>
//               </NavItem>
//             </Nav>
//             <NavLink href="https://app.hellofarmer.de/login">Anmeldung / Gast</NavLink>
//             <NavLink id="signup-btn" href="https://app.hellofarmer.de/register">
//               Registrieren
//             </NavLink>
//           </Collapse>
//         </Navbar>
//         <Container>
//           <Row>
//             <Col>
//             <p>Die folgenden Allgemeinen Geschäftsbedingungen („AGB“) der HelloFarmer UG mit Sitz in Berlin („Verkäufer“, „wir“ oder „uns“) regeln die Benutzung der HelloFarmer-App („App“) und das Vertragsverhältnis zwischen uns einerseits und Ihnen („Käufer“, „Nutzer“ oder „Sie“) andererseits.</p>
// 					<p>§ 1 Allgemeines</p>
// 					<p>§ 2 Vertragsschluss</p>
// 					<p>§ 3 Lieferung</p>
// 					<p>§ 4 Eigentumsvorbehalt</p>
// 					<p>§ 5 Zahlung, Fälligkeit, Zahlungsverzug</p>
// 					<p>§ 6 Preise / Liefer-/Versandkosten</p>
// 					<p>§ 7 Pfandartikel</p>
// 					<p>§ 8 Gewährleistung</p>
// 					<p>§ 9 Transportschäden</p>
// 					<p>§ 10 Widerrufsrecht</p>
// 					<p>§ 11 Ausschluss des Widerrufsrechts</p>
// 					<p>§ 12 Haftungsbeschränkung</p>
// 					<p>§ 13 Datenschutz</p>
// 					<p>§ 14 Anwendbares Recht und Gerichtsstand</p>
// 					<p>§ 15 Verbraucherstreitbeilegungsverfahren</p>
// 					<p>§ 16 Jugendschutz</p>
// 					<p>§ 17 Urheberrechte</p>
// 					<p>§ 18 Änderung der AGB</p>
// 					<p>§ 19 Schlussbestimmungen</p>
// 					<h5><b>§ 1 Allgemeines und Begriffsbestimmungen</b></h5>
// 					<p>(1) Alle Lieferungen und Leistungen, die von dem Verkäufer für den Käufer erbracht werden, erfolgen auf der Grundlage dieser AGB. Durch die Bestellung stimmt der Käufer diesen AGB ohne Einschränkungen zu. Stimmt der Käufer diesen AGB nicht zu, darf er keine Bestellung aufgeben.</p>
//             <p>Falls der Käufer Waren im Namen einer Gesellschaft, eines Unternehmens oder einer Organisation (zusammenfassend „Organisation“) bestellt, sichert er zu, dass er (a) ein
// Bevollmächtigter dieser Organisation ist und (b) befugt ist, diese Organisation an diese AGB zu binden. Diese AGB werden nur in deutscher Sprache angeboten.</p>
//             <p>(2) Zusätzlich gelten die Bestimmungen des Apple App Store („App Store“) und Google Play („Google Play“), die der Nutzer mit dem Anbieter des App Store und Google Play vereinbart.</p>
//             <p>(3) Sofern in diesen AGB besondere Rechte und Pflichten nur für Verbraucher gemäß § 13 BGB oder nur für Unternehmer gemäß § 14 BGB vereinbart werden, gelten folgende Begriffsbestimmungen:</p>
//             <p>(4) „Verbraucher“ im Sinne von § 13 BGB ist jede natürliche Person, die ein Rechtsgeschäft zu einem Zwecke abschließt, der weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden kann.</p>
//             <p>(5) „Unternehmer“ im Sinne von § 14 BGB ist eine natürliche oder juristische Person oder eine rechtsfähige Personengesellschaft, die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer gewerblichen oder selbständigen beruflichen Tätigkeit handelt. Eine rechtsfähige Personengesellschaft ist eine Personengesellschaft, die mit der Fähigkeit ausgestattet ist, Rechte zu erwerben und Verbindlichkeiten einzugehen.
//             </p>
//             <h5><b>§ 2 Vertragsschluss</b></h5>
//             <p>(1) Die Produktdarstellungen in der App sind keine verbindlichen Angebote des Verkäufers, sondern stellen lediglich eine Aufforderung des Verkäufers an den Käufer dar, selber ein Kaufangebot an den Verkäufer abzugeben. Insbesondere durch das Einlegen von Waren in den Warenkorb entsteht noch kein Kaufvertrag zwischen dem Käufer und dem Verkäufer. Die Produktdarstellungen müssen nicht immer mit dem Aussehen der gelieferten Produkte übereinstimmen. Mängelansprüche bestehen diesbezüglich insoweit nicht, als die Veränderungen für Sie zumutbar sind und nicht von einer Beschaffenheitsvereinbarung abweichen.</p>
//             <p>(2) Mit Absendung der Bestellung über die App durch Anklicken des Buttons „Jetzt kaufen“ gibt der Käufer ein verbindliches Angebot über die durch ihn in den Warenkorb gelegten Waren zu dem ausgewiesenen Preis ab. Der Mindestbestellwert beträgt 20,00 Euro.</p>
//             <p>(3) Nach Aufgabe der Bestellung kommt ein verbindlicher Vertrag mit uns zustande, wenn die Bestellung durch eine unverzüglich in der App angezeigte
// Bestellbestätigung angenommen wird und/oder der Käufer automatisch aufgefordert wird, die von ihm gewünschte Zahlungsart auszuwählen. Die Zahlung des Kaufpreises wird mit Vertragsschluss fällig. Sollte die Lieferung der bestellten Ware nicht möglich sein, etwa, weil die entsprechende Ware nicht auf Lager ist, wird dem Käufer keine Bestellbestätigung übermittelt bzw. erhält der Käufer keine Aufforderung zur Auswahl der Zahlungsart. t. In diesem Fall kommt ein Vertrag nicht zustande, worüber der Käufer unverzüglich informiert wird. Sollten Waren, die der Käufer gemäß Satz 1 verbindlich bestellt hat, ausnahmsweise doch nicht verfügbar sein, werden wir diesen hierüber unverzüglich informieren, nachdem wir Kenntnis von einem solchen Umstand erlangt haben. In einem solchen Fall werden wir den bereits entrichteten Kaufpreis für die betroffene Ware über die jeweilige Zahlungsart zurückerstatten oder ggf. mit dem Kunden individuell abstimmen, ob ein aus Sicht des Kunden gleichwertiges Ersatzprodukt gegen Aufrechterhaltung der Zahlungsverpflichtung geliefert werden kann.
// </p>
//             <p>(4) Waren können nur in handelsüblichen Mengen bestellt werden.</p>
//             <p>(5) Der Käufer kann die aktuell gültigen AGB des Verkäufers jederzeit
// unter https://www.hellofarmer.de/agb/einsehen, herunterladen oder speichern.</p>
//             <h5><b>§ 3 Lieferung</b></h5>
//             <p>(1) Sofern vor Vertragsschluss nichts Anderes vereinbart wird, erfolgt die Lieferung der Waren an die vom Käufer während des Bestellvorgangs ausgewählte Adresse und/oder Person in unserem Liefergebiet innerhalb Deutschlands. Lieferungen in andere Länder / Personen sind ausgeschlossen.</p>
//             <p>(2) Bitte beachten Sie, dass Waren, welche den Jugendschutzbestimmungen unterliegen (z.B. Tabakwaren, Alkoholika etc.) nur an volljährige Besteller (d.h. Personen ab dem vollendeten 18. Lebensjahr), welche sich bei der Lieferung durch einen Personalausweis auszuweisen haben, abgeliefert werden. Eine Ablieferung der bestellten Waren an Personen unter 18 Jahren findet nicht statt. Zudem erfolgt die Ablieferung der bestellten Waren nur an den Besteller persönlich. Zu diesem Zwecke sind wir berechtigt, die Vorlage eines Personalausweises oder eines vergleichbaren amtlichen Dokuments (Reisepass, Führerschein etc.) bei der Ablieferung zu verlangen. Sollte die Lieferung infolge einer unzureichenden Altersverifikation und/oder unzureichenden Personenidentifikation nicht übergeben werden können, sind die Mehrkosten für die Rücksendung der Bestellung vom Besteller zu tragen. Die Volljährigkeit ist darüber hinaus bereits während des Bestellvorgangs zu bestätigen.</p>
//             <p>(3) Das Datum der Lieferung der Ware an den Käufer durch den Verkäufer wird durch den Käufer bei der Bestellung angegeben.</p>
//             <p>(4) Lieferfristen für Sonderanfertigungen werden gesondert mit dem Käufer abgesprochen.</p>
//             <p>(5) Die Lieferung erfolgt nur gegen Zahlung der vereinbarten Versandkosten.</p>
//             <p>(6) Wir weisen darauf hin, dass der Verkäufer unabhängig von einer Transportversicherung das Risiko des Untergangs oder der Beschädigung der Ware auf dem Transportweg trägt, sofern der Käufer Verbraucher im Sinne des § 13 BGB (siehe § 1 Abs. 5 der AGB) ist.</p>
//             <p>(7) Ist der Käufer Unternehmer im Sinne von § 14 BGB (siehe § 1 Abs. 6 der AGB) geht die Gefahr des zufälligen Untergangs oder der Beschädigung der Ware auf dem Versandwege mit Abgabe der Ware an ein sorgfältig ausgesuchtes Transportunternehmen auf den Käufer über, sofern ein solches eingesetzt wird. Dies gilt auch, wenn die Leistung in mehreren Teilen erbracht wird.</p>
//             <p>(8) Bei Bestellung von mehreren Waren erfolgt die Lieferung einheitlich. Teillieferungen sind, soweit nichts Anderes vereinbart ist, grundsätzlich nicht möglich. Auf Wunsch des Käufers kann eine Teillieferung erfolgen. Die entstandenen Mehrkosten für den Versand trägt in diesem Fall der Käufer.</p>
//             <h5><b>§ 4 Eigentumsvorbehalt</b></h5>
//             <p>Die bestellte Ware bleibt bis zur vollständigen Bezahlung Eigentum des Verkäufers. Vor Eigentumsübertragung ist eine Verpfändung, Sicherungsübereignung, Verarbeitung oder Umgestaltung ohne ausdrückliche Einwilligung des Verkäufers nicht zulässig.</p>
//             <h5><b>§ 5 Zahlung, Fälligkeit, Zahlungsverzu</b></h5>
//             <p>(1) Der Kaufpreis für die bestellten Waren und für etwaige Liefer- und Versandkosten ist grundsätzlich sogleich nach Zustandekommen eines wirksamen Vertrages zu bezahlen.</p>
//             <p>(2) Ratenzahlungen bedürfen der vorherigen Abstimmung/Vereinbarung mit dem Verkäufer.</p>
//             <p>(3) Im Falle des Zahlungsverzuges ist der Verkäufer berechtigt, nach Ablauf einer angemessenen Frist zur Leistung, von dem Vertrag zurückzutreten.</p>
//             <h5><b>§ 6 Preise / Liefer- / Versandkosten</b></h5>
//             <p>(1) Es gilt ausschließlich der in der App angezeigte Kaufpreis der Ware. Alle Preise in der App verstehen sich inklusive der für das spezifische Produkt geltenden Mehrwertsteuer. Die Mehrwertsteuer wird bei jedem Produkt getrennt ausgewiesen. Der aktuelle Gesamtwert des Warenkorbes wird dem Kunden jederzeit im Warenkorb bzw. in der Bestellübersicht ausgewiesen.</p>
//             <p>(2) Die gesondert angegebenen Liefer- und Versandkosten gelten für die Lieferung / Versand innerhalb Deutschlands und werden dem Käufer vor Abgabe seiner Vertragserklärung deutlich mitgeteilt.</p>
//             <p>(3) Wir bieten Ihnen eine große Auswahl an Zahlungsmöglichkeiten, die in der App jeweils angegeben sind. Bezahlen Sie Ihren Online-Einkauf ganz bequem per Kreditkarte, PayPal oder Apple Pay. Selbstverständlich ist jede Zahlungsart für Sie kostenlos. Es entstehen keine separaten Gebühren.</p>
//             <h5><b>§ 7 Pfandartikel</b></h5>
//             <p>(1) Bei Getränken, auf deren Verpackung ein Pfand erhoben wird (Pfandgut), wird das Pfand automatisch dem ausgewiesenen Preis hinzugerechnet und die Höhe des Pfands separat ausgewiesen.</p>
//             <p>(2) Pfandgut kann in allen REWE-Märkten gegen Pfanderstattung abgegeben werden.</p>
//             <h5><b>§ 8 Gewährleistung</b></h5>
//             <p>(1) Ist der Käufer Verbraucher im Sinne des § 13 BGB (siehe § 1 Abs. 5 der AGB), so beträgt die Gewährleistungsfrist für neue Sachen 24 Monate ab der Ablieferung der
// Sache. Schadensersatzansprüche sind hiervon ausgenommen und richten sich nach § 12 dieser AGB.</p>
//             <p>(2) Ist der Käufer Unternehmer im Sinne des § 14 BGB (siehe § 1 Abs. 6 der AGB), so beträgt die Gewährleistungsfrist für neue Sachen ein Jahr ab Ablieferung der Sache. Für gebrauchte Sachen wird gegenüber einem Unternehmer keine Gewährleistung übernommen. Schadensersatzansprüche sind hiervon ausgenommen und richten sich nach § 12 dieser AGB.</p>
//             <p>(3) Schäden, die durch unsachgemäße oder vertragswidrige Maßnahmen des Käufers, bei Aufstellung, Anschluss, Bedienung oder Lagerung hervorgerufen werden, begründen keinen Anspruch gegen den Verkäufer. Die Unsachgemäßheit und Vertragswidrigkeit bestimmt sich insbesondere nach den Angaben des Herstellers der gelieferten Waren.</p>
//             <p>(4) Ist der Käufer Unternehmer im Sinne von § 14 BGB (siehe § 1 Abs. 6 der AGB), stehen ihm Gewährleistungsansprüche wegen bestehender Transportschäden nur zu, wenn er seiner Untersuchungs- und Anzeigepflicht gemäß § 9 dieser AGB nachgekommen ist.</p>
//             <p>(5) Im Übrigen richten sich die Ansprüche des Käufers gegen den Verkäufer bei Mängeln nach den gesetzlichen Regelungen.</p>
//             <h5><b>§ 9 Transportschäden</b></h5>
//             <p>Sofern der Käufer Unternehmer im Sinne von § 14 BGB (siehe § 1 Abs. 6 der AGB), ist, hat er die Ware sofort nach Empfangnahme auf Transportschäden zu untersuchen. Feststellbare Transportschäden sind unverzüglich schriftlich beim Verkäufer anzuzeigen. Verpackungsschäden muss sich der Käufer bei Annahme der Ware von dem Transportunternehmen schriftlich bestätigen lassen. Zeigt sich später ein solcher Mangel, so muss die Anzeige unverzüglich nach der Entdeckung erfolgen.</p>
//             <h5><b>§ 10 Widerrufsrecht</b></h5>
//             <p>Sofern Sie unsere Waren zu einem Zweck erwerben, der weder Ihrer gewerblichen noch Ihrer selbständigen beruflichen Tätigkeit zugerechnet werden kann, gelten für Sie als Verbraucher im Sinne des Gesetzes (§ 13 Bürgerliches Gesetzbuch) die folgenden Bestimmungen:</p>
//             <p>Widerrufsrecht</p>
//             <p>Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.</p>
//             <p>Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die Waren in Besitz genommen haben bzw. hat.</p>
//             <p>Um Ihr Widerrufsrecht auszuüben, müssen Sie uns</p>
//             <p>Hellofarmer UG,</p>
//             <p>Burggrafenstraße 16,</p>
//             <p>10787 Berlin, Deutschland,</p>
//             <p>E-Mail: info@hellofarmer.de</p>
//             <p>mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.</p>
//             <p>Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.</p>
//             <p>Folgen des Widerrufs</p>
//             <p>Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart;</p>
//             <p>in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.</p>
//             <p>Wir holen die Waren ab. Sie müssen für einen etwaigen Wertverlust der Waren nur aufkommen, wenn dieser Wertverlust auf einen zur Prüfung der Beschaffenheit, Eigenschaften und Funktionsweise der Waren nicht notwendigen Umgang mit ihnen zurückzuführen ist.</p>
//             <p>Ende der Widerrufsbelehrung</p>
//             <p>Muster-Widerrufsformular</p>
//             <p>(Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück.)</p>
//             <p>- An Hellofarmer UG, Burggrafenstraße 16, 10787 Berlin, Deutschland, E-Mail: info@hellofarmer.de</p>
//             <p>- Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über den Kauf der folgenden Waren (*)/die Erbringung der folgenden Dienstleistung (*)</p>
//             <p>- Bestellt am (*)/erhalten am (*)</p>
//             <p>- Name des/der Verbraucher(s)</p>
//             <p>- Anschrift des/der Verbraucher(s)</p>
//             <p>- Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)</p>
//             <p>- Datum</p>
//             <hr></hr>
//             <p>(*) Unzutreffendes streichen.</p>
//             <h5><b>§ 11 Ausschluss des Widerrufsrechts</b></h5>
//             <p>(1) Das Widerrufsrecht besteht laut Gesetz nicht bei Fernabsatzverträgen</p>
//             <p>- zur Lieferung von Waren, die schnell verderben können oder deren Verfallsdatum schnell überschritten würde,</p>
//             <p>- zur Lieferung versiegelter Waren, die aus Gründen des Gesundheitsschutzes oder der Hygiene nicht zur Rückgabe geeignet sind, wenn ihre Versiegelung nach der Lieferung entfernt wurde</p>
//             <p>- zur Lieferung alkoholischer Getränke, deren Preis bei Vertragsschluss vereinbart wurde, die aber frühestens 30 Tage nach Vertragsschluss geliefert werden können und deren aktueller Wert von Schwankungen auf dem Markt abhängt, auf die der Unternehmer keinen Einfluss hat,</p>
//             <p>- in allen anderen in § 312g Abs. 2 BGB genannten Fällen.</p>
//             <p>(2) Das Widerrufsrecht erlischt bei einem Vertrag zur Erbringung von Dienstleistungen auch dann, wenn der Unternehmer die Dienstleistung vollständig erbracht hat und mit der Ausführung der Dienstleistung erst begonnen hat, nachdem der Verbraucher dazu seine ausdrückliche Zustimmung gegeben hat und gleichzeitig seine Kenntnis davon bestätigt hat, dass er sein Widerrufsrecht bei vollständiger Vertragserfüllung durch den Unternehmer verliert.</p>
//             <h5><b>§ 12 Haftungsbeschränkung</b></h5>
//             <p>(1) Der Verkäufer haftet unbeschränkt nach Maßgabe der gesetzlichen Bestimmungen, sofern der Käufer Ansprüche geltend macht, die auf Vorsatz oder grober Fahrlässigkeit beruhen sowie in Fällen der Übernahme einer Beschaffenheitsgarantie, beim arglistigen Verschweigen von Mängeln und bei einer Verletzung von Leben, Körper und
// Gesundheit. Bei leicht fahrlässig verursachten Sach- und Vermögensschäden durch den Verkäufer haftet der Verkäufer (ausgenommen die im vorstehenden Satz 1 genannten Fälle) nur im Falle der Verletzung einer wesentlichen Vertragspflicht, jedoch der Höhe nach begrenzt auf den bei Vertragsschluss vorhersehbaren und vertragstypischen Schaden. Wesentliche Vertragspflichten sind solche, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der Vertragspartner regelmäßig vertrauen darf. Soweit der Verkäufer Leistungen unentgeltlich erbringt, haftet er nur nach Maßgabe des vorstehenden § 12 (1) Satz 1.</p>
//             <p>(2) Die Haftung nach dem Produkthaftungsgesetz bleibt unberührt.</p>
//             <p>(3) Eine über das Vorstehende hinausgehende Haftung des Verkäufers ist ausgeschlossen.</p>
//             <p>(4) Die vorstehenden Haftungsausschlüsse und -beschränkungen gelten auch im Hinblick auf die Haftung der Angestellten, Arbeitnehmer, Mitarbeiter, Vertreter und Erfüllungsgehilfen des Verkäufers.</p>
//             <h5><b>§ 13 Datenschutz</b></h5>
//             <p>Unsere Datenschutzbestimmungen finden Sie unter https://www.hellofarmer.de/privacy/.</p>
//             <h5><b>§ 14 Anwendbares Recht und Gerichtsstand</b></h5>
//             <p>(1) Alle Streitigkeiten aus diesem Rechtsverhältnis unterliegen dem Recht der Bundesrepublik Deutschland mit Ausnahme seiner kollisionsrechtlichen Bestimmungen. Die Geltung von UN-Kaufrecht ist ausgeschlossen. Sofern der Nutzer Verbraucher ist, steht ihm ungeachtet dessen nach Art. 6 Abs. 2 der Verordnung (EG) 593/2008 auch der Schutz der zwingenden Bestimmungen desjenigen Rechts zu, das ohne diese Klausel anzuwenden wäre.</p>
//             <p>(2) Für Streitigkeiten aus oder im Zusammenhang mit der Lieferung der Waren und/oder diesen AGB ist ausschließlicher Gerichtsstand Berlin, sofern der Käufer Kaufmann ist oder es sich beim Käufer um eine juristische Person des öffentlichen Rechts oder um ein öffentlich-rechtliches Sondervermögen handelt oder der Käufer keinen festen Wohnsitz in Deutschland hat, der Käufer den Wohnsitz oder seinen gewöhnlichen Aufenthaltsort nach Wirksamwerden dieser AGB ins Ausland verlegt hat oder wenn der Wohnsitz oder gewöhnlicher Aufenthaltsort des Käufers im Zeitpunkt der Klageerhebung nicht bekannt ist.</p>
//             <h5><b>§ 15 Verbraucherstreitbeilegungsverfahren</b></h5>
//             <p>(1) Wir weisen darauf hin, dass wir zu einer Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle nicht verpflichtet und nicht bereit sind. Vielmehr sind wir stets darum bemüht, etwaige Konflikte mit unseren Kunden selbst zu lösen.</p>
//             <p>(2) Die EU-Kommission hat eine Internetplattform zur Online-Beilegung von Streitigkeiten („OS-Plattform“) zwischen Unternehmern und Verbrauchern eingerichtet. Die OS-Plattform ist erreichbar unter www.ec.europa.eu/consumers/odr/.</p>
//             <h5><b>§ 16 Jugendschutz</b></h5>
//             <p>(1) Die Übergabe von Waren, die gesetzlichen Verkaufsbeschränkungen unterliegen, erfolgt nur an Personen, die die gesetzlichen Voraussetzungen erfüllen. Die Mitarbeiter der Hellofarmer UG dürfen zu diesem Zweck die Vorlage eines amtlichen Lichtbildausweises verlangen.</p>
//             <p>(2) Es besteht die Möglichkeit, alkoholische Waren und Tabakwaren zu bestellen. Hellofarmer weist darauf hin, dass alkoholische Waren und Tabakwaren nur an Personen über 18 Jahre ausgegeben werden (vgl. hierzu auch § 3 Abs. 2).</p>
//             <h5><b>§ 17 Urheberrechte</b></h5>
//             <p>Die HelloFarmer-App ist urheberrechtlich geschützt. Wir räumen Ihnen das nicht ausschließliche, nicht unterlizenzierbare und nicht übertragbare, zeitlich beschränkte und jederzeit widerrufliche Recht ein, die App zu nutzen, um Waren gemäß diesen AGB über die App zu bestellen. Eine weitergehende Verwendung der App, einschließlich der dargestellten Bilder, Zeichen, Symbole oder Produktbeschreibungen ist ohne unsere ausdrückliche Zustimmung nicht gestattet. Bei der Nutzung der App ist es Ihnen insbesondere untersagt, die auf der App dargestellten Informationen, insbesondere zu der auf der App angebotenen Ware, massenhaft zu extrahieren (z.B. durch sog. „Scraping“) und/oder die Informationen außerhalb der App weiterzuverwenden.</p>
//             <h5><b>§ 18 Änderung der AGB</b></h5>
//             <p>(1) Wir behalten uns vor, unsere AGB jederzeit zu ändern, außer dies ist für den Nutzer nicht zumutbar. Für neue Vertragsabschlüsse gelten dann jeweils die AGB in der zum Vertragsabschluss geltenden Fassung.</p>
//             <p>(2) Bei einem laufenden Dauerschuldverhältnis werden wir Ihnen eine Änderung der AGB mindestens 4 Wochen mittels einer Push-Benachrichtigung innerhalb der App oder per E- Mail mitteilen. Widersprechen Sie innerhalb dieser 4-Wochen-Frist nicht oder öffnen die App erneut, gelten die geänderten AGB mit Wirkung für die Zukunft als angenommen und werden zum Zeitpunkt des Wirksamwerdens Vertragsbestandteil. Wir werden Sie in der Mitteilung, die die geänderten Bedingungen enthält, auf die Wirkung des Schweigens und die Bedeutung der 4-Wochenfrist hinweisen.</p>
//             <p>Eine Änderung der AGB kommt insbesondere in Betracht, soweit</p>
//             <p>1. die Änderungen oder Anpassungen lediglich vorteilhaft für die Nutzer sind,</p>
//             <p>2. dieÄnderungenoderAnpassungenerforderlichsind,umeine
// Übereinstimmung mit geltendem Recht herzustellen, vor allem auch, wenn sich die geltende Rechtslage ändert, oder um einem Gerichtsurteil oder einer Behördenentscheidung nachzukommen,</p>
//             <p>3. wenn die Änderungen oder Anpassungen ohne wesentlichen Einfluss auf Funktionen der Dienste oder rein technischer oder organisatorischer Art sind, oder</p>
//             <p>4. dieszusätzliche,gänzlichneueDiensteoderLeistungenbetrifft,dieeiner Beschreibung in den AGB bedürfen, es sei denn, dass dies für das laufende Nutzungsverhältnis nachteilig wäre.</p>
//             <p>(3) Der vorstehende Absatz (2) gilt nicht für solche Änderungen, die zu einer Änderung des Äquivalenzverhältnisses von Leistung und Gegenleistung oder zu einer Umgestaltung des Vertragsgefüges insgesamt führen würden. In diesem Fall werden wir Ihnen anbieten, den Vertrag zu den ursprünglichen Bedingungen fortzusetzen.</p>
//             <h5><b>§ 19 Schlussbestimmungen</b></h5>
//             <p>(1) Vertragssprache ist deutsch.</p>
//             <p>(2) Die Unwirksamkeit einer oder mehrerer Bestimmungen dieses Vertrages lässt die Wirksamkeit des Vertrages im Übrigen unberührt. Anstelle der unwirksamen Bestimmungen treten die geltenden gesetzlichen Vorschriften.</p>
//             <p>Stand: September, 2021</p>
//             </Col>
//           </Row>
//         </Container>
//       </section>
//       <footer className="dark-grey">
//         <Row>
//           <Col>
//             <Row>
//               <Col className="footer-img">
//                 <img className="ml-3 mb-2" src={navLogo} alt="HelloFarmer" />
//               </Col>
//             </Row>
//             <Row>
//               <Col className="full-width-col">
//                 <p className="footer-text">Wir bringen regionale Bio-Produkte direkt per Klick zu Ihnen auf den Tisch</p>
//               </Col>
//               <Col className="full-width-col hide-on-mobile">
                
//               </Col>
//               <Col className="full-width-col">
//                 <h5>Other</h5>
//                 {/* <p><a href="/faq">FAQ</a></p> */}
//                 <p><a href="/datenschutz">Datenschutz</a></p>
//                 <p><a href="/impressum">Impressum</a></p>
//                 <p><a href="/geschaftsbedingungen">Geschäftsbedingungen</a></p>
//               </Col>
//               <Col className="full-width-col">
//                 <h5>Contact</h5>
//                 <p>Hellofarmer UG</p>
//                 <p>Burggrafenstrasse 16</p>
//                 <p>10787 Berlin</p>
//                 <p>info@hellofarmer.de</p>
//               </Col>
//             </Row>
//             {/* <Row>
//               <Col className="footer-socials">
//                 <a href="/">
//                   <FontAwesomeIcon icon={faFacebook} />
//                 </a>
//                 <a href="/">
//                   <FontAwesomeIcon icon={faTwitter} />
//                 </a>
//                 <a href="/">
//                   <FontAwesomeIcon icon={faLinkedin} />
//                 </a>
//                 <a href="/">
//                   <FontAwesomeIcon icon={faInstagram} />
//                 </a>
//                 <a href="/">
//                   <FontAwesomeIcon icon={faYoutube} />
//                 </a>
//               </Col>
//             </Row> */}
//             {/* Remove margin after adding more elements to footer */}
//             <Row className="mb-3">
//               <Col>
//                 <p id="copyright">© 2021 HelloFarmer UG, all rights reserved</p>
//               </Col>
//             </Row>
//           </Col>
//         </Row>
//       </footer>
//     </div>
//   );
// }

// function ComingSoon() {
//   // const [isOpen, setIsOpen] = useState(false);
//   // const toggle = () => setIsOpen(!isOpen);
//   const notifySuccess = () =>
//     toast.success("Email sent!", {
//       position: "top-right",
//       autoClose: 5000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined
//     });
//   const notifyError = () =>
//     toast.error("Oops! Something went wrong...", {
//       position: "top-right",
//       autoClose: 5000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined
//     });
//   function sendEmail(e) {
//     e.preventDefault();

//     emailjs
//       .sendForm(
//         "service_rrsuuou",
//         "template_ny7irfz",
//         e.target,
//         "user_XDrOquYr4X0wis2x2wiYq"
//       )
//       .then(
//         result => {
//           notifySuccess();
//           console.log(result.text);
//         },
//         error => {
//           notifyError();
//           console.log(error.text);
//         }
//       );
//   }
//   return (
//     <div className="App">
//       <section id="hero-section">
//         <Navbar light expand="md">
//           <NavbarBrand href="/">
//             <img className="" src={navLogo} alt="Bautory" />
//           </NavbarBrand>
//         </Navbar>
//         <Container className="coming-soon-section">
//           <Row>
//             <Col className="flex-col">
//               <h1 className="mb-5">Bald Verfügbar</h1>
//               <p className="greyed-out">
//                 Wir arbeiten zur Zeit an der Fertigstellung von Hellofarmer,
//                 bleibt dran für weitere Neuigkeiten
//               </p>
//               <Form onSubmit={sendEmail} inline>
//                 <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
//                   {/* <Label for="exampleName" className="mr-sm-2">
//               Name
//             </Label> */}
//                   <Input type="text" name="from_name" placeholder="Your name" />
//                 </FormGroup>
//                 <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
//                   {/* <Label for="exampleEmail" className="mr-sm-2">
//               Email
//             </Label> */}
//                   <Input
//                     type="email"
//                     name="from_email"
//                     placeholder="Your email"
//                   />
//                 </FormGroup>
//                 <Button className="dark-btn">BENACHRICHTIGEN</Button>
//                 <ToastContainer />
//               </Form>
//               <div className="my-5">
//                 <a href="/">
//                   <FontAwesomeIcon icon={faFacebook} />
//                 </a>
//                 <a href="/">
//                   <FontAwesomeIcon icon={faTwitter} />
//                 </a>
//                 <a href="/">
//                   <FontAwesomeIcon icon={faLinkedin} />
//                 </a>
//                 <a href="/">
//                   <FontAwesomeIcon icon={faInstagram} />
//                 </a>
//                 <a href="/">
//                   <FontAwesomeIcon icon={faYoutube} />
//                 </a>
//               </div>
//             </Col>
//           </Row>
//         </Container>
//       </section>
//       <footer>
//         <Row>
//           <Col>
//             <Row>
//               <Col>
//                 <img className="ml-3 mb-2" src={navLogo} alt="Bautory" />
//               </Col>
//             </Row>
//             <Row>
//               <Col className="footer-socials">
//                 <a href="/">
//                   <FontAwesomeIcon icon={faFacebook} />
//                 </a>
//                 <a href="/">
//                   <FontAwesomeIcon icon={faTwitter} />
//                 </a>
//                 <a href="/">
//                   <FontAwesomeIcon icon={faLinkedin} />
//                 </a>
//                 <a href="/">
//                   <FontAwesomeIcon icon={faInstagram} />
//                 </a>
//                 <a href="/">
//                   <FontAwesomeIcon icon={faYoutube} />
//                 </a>
//               </Col>
//             </Row>
//             {/* Remove margin after adding more elements to footer */}
//             <Row className="mt-5">
//               <Col>
//                 <p>© 2021 HelloFarmer, all rights reserved</p>
//               </Col>
//             </Row>
//           </Col>
//         </Row>
//       </footer>
//     </div>
//   );
// }

export default App;
